import React from 'react';
import DatePicker_, { ReactDatePickerProps } from 'react-datepicker';

const ReactDatePicker = DatePicker_ as any;

type WeekDays =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

type Props = {
  customInput: React.ReactNode;
  selectsRange?: boolean;
};

type DatePickerProps = Omit<Partial<ReactDatePickerProps>, 'selectsRange'> &
  Props;

const weekDaysMapper: {
  [key in WeekDays]: string;
} = {
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'S',
  Sunday: 'S',
};

const DatePicker: React.FC<DatePickerProps> = ({
  dateFormat = 'MM/dd/yyyy',
  customInput,
  ...props
}) => {
  const [date, setDate] = React.useState(new Date());

  return (
    <ReactDatePicker
      {...props}
      selected={props.selected ? props.selected : new Date(date)}
      onChange={
        props?.onChange ? props.onChange : (date: Date) => setDate(date)
      }
      dateFormat={dateFormat}
      formatWeekDay={(weekDay: WeekDays) => weekDaysMapper[weekDay]}
      customInput={customInput}
    />
  );
};

export default DatePicker;
