import React from 'react';
import classNames from 'classnames';
import Text from '../text';
import Icon from '../icon/index';
import LoaderSpinner, { CircleColors } from '../loader-spinner';

type Colors = 'white' | 'gray' | 'red' | 'black' | 'green';

const colorsMapper: {
  [key in Colors]: {
    background: string;
    text: string;
    icon: string;
    circleBackground: string;
    circleColor: CircleColors;
  };
} = {
  white: {
    background:
      'bg-gray-900 dark:bg-gray-100 hover:bg-gray-950 dark:hover:bg-gray-125',
    text: 'text-white dark:text-gray-950',
    icon: 'stroke-white dark:stroke-gray-950',
    circleBackground: 'after:bg-gray-900 after:dark:bg-gray-100',
    circleColor: 'gray',
  },
  gray: {
    background:
      'bg-gray-950/[.06] dark:bg-gray-100/[.08] hover:bg-gray-800/[.04] dark:hover:bg-white/[.06]',
    text: 'text-gray-950 dark:text-white',
    icon: 'fill-gray-950 dark:fill-white',
    circleBackground: 'after:bg-[#E6E7EC] after:dark:bg-[#252734]',
    circleColor: 'gray',
  },
  red: {
    background: 'bg-pink-500 hover:bg-pink-600',
    text: 'text-white',
    icon: 'stroke-white',
    circleBackground: 'after:bg-[#FF1560]',
    circleColor: 'white',
  },
  black: {
    background:
      'bg-gray-50 dark:bg-gray-950 hover:bg-gray-100 hover:dark:bg-gray-925',
    text: 'text-gray-950 dark:text-white',
    icon: 'fill-gray-500',
    circleBackground: 'after:bg-white after:dark:bg-gray-900',
    circleColor: 'gray',
  },
  green: {
    background: 'bg-green-500 hover:bg-green-600',
    text: 'text-white',
    icon: 'stroke-white',
    circleBackground: 'after:bg-green-500 after:dark:bg-green-600',
    circleColor: 'white',
  },
};

type Props = {
  color: Colors;
  as?: 'a' | 'button';
  disabled?: boolean;
  size?: 'x-small' | 'small' | 'regular';
  icon?: React.ComponentProps<typeof Icon>['name'];
  iconRight?: React.ComponentProps<typeof Icon>['name'];
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  iconColor?: string;
  loading?: boolean;
  acting?: boolean;
};

const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      color,
      as = 'button',
      disabled,
      size = 'regular',
      icon,
      iconSize = 16,
      iconColor = '',
      iconRight,
      loading,
      acting,
      children,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `inline-flex justify-center items-center px-6 rounded
      transition-all duration-200 ease-in-out cursor-pointer shrink-0`;
    const className = classNames(
      defaultClassName,
      props.className,
      colorsMapper[color].background,
      {
        'pointer-events-none select-none opacity-20':
          disabled || loading || acting,
        '!py-5 h-14': size === 'regular',
        '!py-3': size === 'small',
        'h-8 !px-3': size === 'x-small',
      },
    );

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        ...props,
        className,
        ref,
      },
      loading ? (
        <LoaderSpinner
          size="w-4 h-4"
          circleBackground={colorsMapper[color].circleBackground}
          color={colorsMapper[color].circleColor}
        />
      ) : (
        <>
          {icon && !acting && (
            <Icon
              name={icon}
              size={iconSize}
              className={classNames(
                'mr-2 !shrink-0',
                iconColor || colorsMapper[color].icon,
              )}
            />
          )}
          {acting && (
            <LoaderSpinner
              size="w-4 h-4"
              circleBackground={colorsMapper[color].circleBackground}
              color={colorsMapper[color].circleColor}
              className="mr-2"
            />
          )}
          <Text
            type="body"
            size="small"
            color={colorsMapper[color].text}
            className="flex items-center"
          >
            {children}
          </Text>
          {iconRight && !acting && (
            <Icon
              name={iconRight}
              size={iconSize}
              className={classNames(
                'ml-2',
                iconColor || colorsMapper[color].icon,
              )}
            />
          )}
        </>
      ),
    );
  },
);

export default Button;
