import { Button, Card, Icon, Text } from '@blastradius/ui';
import { Icons } from '@blastradius/ui/icons';
import classNames from 'classnames';
import React, { RefObject, useEffect } from 'react';
import styles from './dropdown.module.scss';

type DropdownProps = {
  open?: boolean;
  closeOnAnyClick?: boolean;
};

type DropdownItemProps = {
  icon?: Icons;
  iconLeft?: Icons;
  iconLeftColor?: string;
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  as?: React.ElementType;
  active?: boolean;
  selected?: boolean;
  action?: {
    label: string;
    onClick: (e: Event | React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  };
  disabled?: boolean;
};

type DropdownActivatorProps = {
  children: React.ReactElement;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

type DropdownContentProps = {
  width?: string;
  contentId?: string;
  defaultContent?: boolean;
};
type DropdownHeaderProps = {
  onClickOutside?: () => void;
};

type DropdownContentType = React.FC<
  DropdownContentProps & React.HTMLAttributes<HTMLDivElement>
>;
type DropdownHeaderType = React.FC<
  DropdownHeaderProps & React.HTMLAttributes<HTMLElement>
>;
type DropdownItemsType = React.FC<React.HTMLAttributes<HTMLUListElement>>;
type DropdownItemType = React.FC<
  DropdownItemProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>;
type DropdownActivatorType = React.FC<
  DropdownActivatorProps & React.HTMLAttributes<HTMLElement>
>;
type DropdownItemSeparatorType = React.FC<React.HTMLAttributes<HTMLDivElement>>;

type SubComponents = {
  Content: DropdownContentType;
  Header: DropdownHeaderType;
  Items: DropdownItemsType;
  Item: DropdownItemType;
  Activator: DropdownActivatorType;
  ItemSeparator: DropdownItemSeparatorType;
  NavigateItem: React.FC<
    DropdownNavigateItemProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
  >;
};

type DropdownContextProps = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  dropdownActivatorId: string;
  activeContent: string;
  setActiveContent: React.Dispatch<React.SetStateAction<string>>;
};

/* istanbul ignore next */
const DropdownContext = React.createContext<DropdownContextProps>({
  isOpened: false,
  setIsOpened: () => void 1,
  dropdownActivatorId: '',
  activeContent: '',
  setActiveContent: () => void 1,
});

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: RefObject<HTMLDivElement>,
  dropdownActivatorId: string,
  func: () => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const hasDropdownActivatorId = event
        .composedPath()
        .some(
          (path) =>
            (path as HTMLElement).getAttribute?.('id') === dropdownActivatorId,
        );

      if (hasDropdownActivatorId) {
        return true;
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        func();
      }
      return true;
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, dropdownActivatorId, func]);
}

const Dropdown: React.FC<DropdownProps & React.HTMLAttributes<HTMLDivElement>> &
  SubComponents = ({
  open = false,
  children,
  closeOnAnyClick = true,
  ...props
}) => {
  const [isOpened, setIsOpened] = React.useState(open);
  const [activeContent, setActiveContent] = React.useState('');
  const defaultClassName = 'relative';
  const className = classNames(defaultClassName, props.className);
  const dropdownActivatorId = React.useMemo(
    () => Math.random().toString(36).slice(2),
    [],
  );

  function closeDropdown(e: MouseEvent) {
    const hasDropdownActivatorId = e
      .composedPath()
      .some(
        (path) =>
          (path as HTMLElement).getAttribute?.('id') === dropdownActivatorId,
      );

    const isInputElement = e
      .composedPath()
      .some((path) =>
        ['INPUT', 'TEXTAREA'].includes((path as HTMLElement).tagName),
      );

    if (!hasDropdownActivatorId && !isInputElement) {
      setIsOpened(false);
    }
  }

  React.useEffect(() => {
    if (closeOnAnyClick) document.addEventListener('click', closeDropdown);

    return () => {
      if (closeOnAnyClick) document.removeEventListener('click', closeDropdown);
    };
  }, []);

  React.useEffect(() => {
    if (!isOpened) {
      setActiveContent('');
    }
  }, [isOpened]);

  const ref = React.useRef<HTMLDivElement>(null);
  if (!closeOnAnyClick) {
    useOutsideAlerter(ref, dropdownActivatorId, () => {
      if (isOpened) setIsOpened(false);
    });
  }

  return (
    <DropdownContext.Provider
      value={{
        isOpened,
        setIsOpened,
        dropdownActivatorId,
        activeContent,
        setActiveContent,
      }}
    >
      <div {...props} className={className} ref={ref}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

const DropdownContent: DropdownContentType = ({
  children,
  width = 'w-60',
  contentId = '',
  defaultContent = false,
  ...props
}) => {
  const { isOpened, activeContent } = React.useContext(DropdownContext);
  const defaultClassName = 'right-0 top-full mt-2';
  const className = classNames(defaultClassName, width, props.className, {
    hidden:
      !isOpened ||
      (contentId && activeContent !== contentId && !defaultContent) ||
      (!contentId && activeContent !== ''),
  });

  return (
    <Card {...props} position="absolute" className={className}>
      {children}
    </Card>
  );
};
Dropdown.Content = DropdownContent;

const DropdownHeader: DropdownHeaderType = ({
  children,
  onClickOutside,
  ...props
}) => {
  const { isOpened, setIsOpened, dropdownActivatorId } =
    React.useContext(DropdownContext);

  // NOTE: Component "Header" not currently used, so haven't tested
  // But the following useOutsiderAlerter has been added to parent Dropdown
  // So this may be redundant
  const ref = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(ref, dropdownActivatorId, () => {
    onClickOutside?.();
    if (isOpened) setIsOpened(false);
  });

  return (
    <div ref={ref}>
      <header {...props}>{children}</header>
      <hr className="border-gray-100 dark:border-gray-700" />
    </div>
  );
};
Dropdown.Header = DropdownHeader;

const DropdownItems: DropdownItemsType = ({ children, ...props }) => {
  const defaultClassName = 'flex flex-col gap-1 p-2';
  const className = classNames(defaultClassName, props.className);

  return (
    <ul className={className} {...props}>
      {children}
    </ul>
  );
};
Dropdown.Items = DropdownItems;

const DropdownItem: DropdownItemType = ({
  icon,
  iconLeft,
  iconLeftColor,
  iconSize = 16,
  as = 'span',
  active,
  children,
  selected,
  action,
  disabled,
  ...props
}) => {
  const { isOpened, setIsOpened } = React.useContext(DropdownContext);

  const defaultClassName = 'flex w-full group-deep';
  const textClassName = classNames(
    `flex items-center w-full rounded pl-4 pr-3 py-3
     bg-transparent hover:bg-gray-800/[.06] hover:dark:bg-white/[.06]
     cursor-pointer transition duration-200 ease-in-out text-left relative`,
    {
      'justify-between': icon,
      relative: active,
    },
  );

  function handleActionClick(
    e: Event | React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) {
    action?.onClick?.(e);
  }

  return (
    <li
      className={classNames(defaultClassName, props.className, {
        'pointer-events-none opacity-50': disabled,
      })}
      onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        if (action?.onClick) handleActionClick(e);

        if (isOpened) setIsOpened(false);
      }}
    >
      <Text
        {...props}
        type="label"
        className={classNames(textClassName, styles.dropdownText)}
        as={as}
      >
        {selected && (
          <div className="w-1 h-1 bg-gray-950 dark:bg-white absolute left-1.5 rounded-full" />
        )}

        {iconLeft && (
          <Icon
            name={iconLeft}
            size={iconSize}
            className={classNames(
              iconLeftColor || 'fill-black dark:fill-white',
              'mr-2 pointer-events-none',
            )}
          />
        )}
        {active && (
          <div
            className="absolute h-1 w-1 rounded-full bg-gray-950 dark:bg-white -ml-2.5 mr-1.5"
            data-testid="dropdown-item-active"
          />
        )}
        {children}
        {icon && (
          <Icon name={icon} size={iconSize} data-testid="dropdown-item-icon" />
        )}

        {action?.label && (
          <Button
            color="gray"
            size="x-small"
            className="opacity-0 invisible group-deep-hover:opacity-100 group-deep-hover:visible absolute right-2 !h-6 transition-opacity ease-in-out duration-200"
          >
            {action.label}
          </Button>
        )}
      </Text>
    </li>
  );
};
Dropdown.Item = DropdownItem;

const DropdownActivator: DropdownActivatorType = ({
  children,
  active,
  disabled = false,
  loading,
  ...props
}) => {
  const { isOpened, setIsOpened, dropdownActivatorId } =
    React.useContext(DropdownContext);

  const className = classNames(props.className, {
    [styles.dropdownActive]: active && isOpened && !loading,
    [styles.dropdownHoverActive]: active && !loading,
    'pointer-events-none': disabled,
  });

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setIsOpened((prevValue) => !prevValue);
    props.onClick?.(e);
  }

  return React.cloneElement(children, {
    ...props,
    id: dropdownActivatorId,
    onClick: handleClick,
    className,
    role: 'listbox',
  });
};
Dropdown.Activator = DropdownActivator;

const DropdownItemSeparator = ({ ...props }) => {
  const defaultClassName = 'bg-gray-100 dark:bg-gray-700 w-full h-px';

  const className = classNames(props.className, defaultClassName);

  return <div className={className} data-testid="dropdown-item-separator" />;
};
Dropdown.ItemSeparator = DropdownItemSeparator;

type DropdownNavigateItemProps = {
  to: string;
} & DropdownItemProps;

const DropdownNavigateItem: React.FC<
  DropdownNavigateItemProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ to, ...itemProps }) => {
  const { setActiveContent } = React.useContext(DropdownContext);

  return (
    <DropdownItem
      {...itemProps}
      onClick={(e) => {
        e.stopPropagation();
        setActiveContent(to);
      }}
    />
  );
};
Dropdown.NavigateItem = DropdownNavigateItem;

export default Dropdown;
