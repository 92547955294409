import React from 'react';
import classNames from 'classnames';

type Types = 'simple' | 'composed' | 'vendor';

export type Sizes =
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 26
  | 28
  | 32
  | 38
  | 56
  | 60
  | 64
  | 148;

export type Icons =
  | 'application'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'at'
  | 'carrot-down'
  | 'carrot-left'
  | 'carrot-right'
  | 'carrot-right-red'
  | 'caution'
  | 'check-green'
  | 'check'
  | 'circle-red'
  | 'close'
  | 'cloud'
  | 'copy'
  | 'data-object'
  | 'device'
  | 'identity'
  | 'incident-open'
  | 'incident-reopen'
  | 'incident-on-hold'
  | 'incident-remediated'
  | 'incident-remediated-simple'
  | 'incident-closed'
  | 'incident-closed-simple'
  | 'incident-pending'
  | 'incident-pending-simple'
  | 'location'
  | 'mail'
  | 'moon'
  | 'nav'
  | 'notifications'
  | 'phone'
  | 'search'
  | 'sun'
  | 'tag'
  | 'user'
  | 'aws'
  | 'crowdstrike'
  | 'duo'
  | 'microsoft-defender'
  | 'microsoft-office'
  | 'okta'
  | 'slack'
  | 'fireeyehx'
  | 'palo-alto-networks'
  | 'sentinel-one'
  | 'z-scaler'
  | 'more'
  | 'caution-green'
  | 'home'
  | 'cards'
  | 'list'
  | 'exit'
  | 'organization'
  | 'share'
  | 'alert'
  | 'settings'
  | 'incidents'
  | 'circle-carrot-red'
  | 'involved'
  | 'target'
  | 'play'
  | 'circle'
  | 'circle-minus'
  | 'circle-check'
  | 'steps'
  | 'carrot-right-grey'
  | 'check-grey'
  | 'containment'
  | 'remediation'
  | 'remove'
  | 'tasks'
  | 'check-button-transparent'
  | 'block'
  | 'lock'
  | 'lock-solid'
  | 'plus'
  | 'exclamation'
  | 'circle-info'
  | 'circle-warning'
  | 'square-plus'
  | 'circle-close'
  | 'eye-open'
  | 'eye-close'
  | 'edit'
  | 'flag'
  | 'triangle-down'
  | 'bell'
  | 'mapping'
  | 'plug'
  | 'false-positive'
  | 'export'
  | 'return'
  | 'calendar'
  | 'menu'
  | 'menu-hamburger'
  | 'delete'
  | 'time'
  | 'circle-check-green'
  | 'switch'
  | 'circle-exclamation-red'
  | 'gear'
  | 'endpoint'
  | 'phishing'
  | 'mitre'
  | 'file'
  | 'process'
  | 'caution-yellow'
  | 'bullet-green'
  | 'shadow'
  | 'hand'
  | 'key'
  | 'image'
  | 'hash'
  | 'circle-blocked'
  | 'check-shield'
  | 'paper'
  | 'globe'
  | 'ip'
  | 'trusted'
  | 'gears-group'
  | 'upload'
  | 'paperclip'
  | 'side-panel'
  | 'blocked'
  | 'escalate'
  | 'google-workspace'
  | 'user-shared'
  | 'error-warning'
  | 'bec'
  | 'knowbe4'
  | 'mimecast'
  | 'message-ballon'
  | 'circle-ignored'
  | 'review'
  | 'suspicious-login'
  | 'cribl'
  | 'tools'
  | 'microsoft-teams'
  | 'workflows'
  | 'pulsesecure'
  | 'pencil-plus'
  | 'proofpoint'
  | 'network'
  | 'server'
  | 'empty-list'
  | 'forward-rule'
  | 'link'
  | 'shared-account'
  | 'triage'
  | 'alert-empty'
  | 'kebab-menu'
  | 'chat'
  | 'dashboard'
  | 'triage-message'
  | 'fleet'
  | 'sonic-wall'
  | 'cisco'
  | 'fortinet'
  | 'download-file'
  | 'shield'
  | 'object-storage'
  | 'rapid7'
  | 'ms-entra-id'
  | 'recipients'
  | 'artifact'
  | 'benign-alert'
  | 'malicious-alert'
  | 'description'
  | 'red-flag'
  | 'notebook'
  | 'notebook-empty'
  | 'question'
  | 'barracuda'
  | 'documentation'
  | 'atlassian'
  | 'netskope'
  | 'trendmicro'
  | 'audit-logs'
  | 'app'
  | 'forcepoint'
  | 'logs'
  | 'avanan'
  | 'message'
  | 'resource'
  | 'process-artifact'
  | 'splunk'
  | 'trusted-cloud'
  | 'internal-review'
  | 'aruba'
  | 'manage_engine'
  | 'datadog'
  | 'dragos'
  | 'onepassword'
  | 'darktrace'
  | 'adaptive_shield'
  | 'download'
  | 'expand'
  | 'minimize'
  | 'string'
  | 'number'
  | 'ai-robot'
  | 'exclude'
  | 'history'
  | 'add-to-search'
  | 'open-in-new-tab'
  | 'log'
  | 'alert-brief'
  | 'checkpoint'
  | 'crosshair'
  | 'chip'
  | 'elastic'
  | 'vectra'
  | 'zero_fox'
  | 'imperva'
  | 'sensor'
  | 'cloud-resource'
  | 'file-hash'
  | 'question-mark'
  | 'malicious-indicator'
  | 'new-tab'
  | 'salt'
  | 'columns'
  | 'card-view'
  | 'radiant_security';

export const iconsMapper: {
  [key in Icons]: {
    type: Types;
    stroke?: boolean;
    className?: string;
  };
} = {
  application: {
    type: 'simple',
  },
  'arrow-down': {
    type: 'simple',
  },
  'arrow-left': {
    type: 'simple',
  },
  'arrow-right': {
    type: 'simple',
  },
  at: {
    type: 'simple',
  },
  'carrot-down': {
    type: 'simple',
  },
  'carrot-left': {
    type: 'simple',
  },
  'carrot-right': {
    type: 'simple',
  },
  'carrot-right-red': {
    type: 'composed',
  },
  caution: {
    type: 'composed',
  },
  'check-green': {
    type: 'composed',
  },
  check: {
    type: 'simple',
  },
  'circle-red': {
    type: 'composed',
  },
  close: {
    type: 'simple',
  },
  cloud: {
    type: 'simple',
  },
  copy: {
    type: 'simple',
  },
  'data-object': {
    type: 'simple',
  },
  device: {
    type: 'simple',
  },
  identity: {
    type: 'simple',
  },
  'incident-open': {
    type: 'composed',
  },
  'incident-reopen': {
    type: 'composed',
  },
  'incident-on-hold': {
    type: 'composed',
  },
  'incident-remediated': {
    type: 'composed',
  },
  'incident-remediated-simple': {
    type: 'composed',
  },
  'incident-closed': {
    type: 'composed',
  },
  'incident-closed-simple': {
    type: 'composed',
  },
  'incident-pending': {
    type: 'composed',
  },
  'incident-pending-simple': {
    type: 'composed',
  },
  location: {
    type: 'simple',
  },
  'shared-account': {
    type: 'simple',
  },
  mail: {
    type: 'simple',
  },
  chat: {
    type: 'simple',
  },
  moon: {
    type: 'simple',
  },
  nav: {
    type: 'simple',
  },
  notifications: {
    type: 'simple',
  },
  phone: {
    type: 'simple',
  },
  search: {
    type: 'simple',
  },
  sun: {
    type: 'simple',
  },
  tag: {
    type: 'simple',
  },
  user: {
    type: 'simple',
  },
  aws: {
    type: 'vendor',
  },
  crowdstrike: {
    type: 'vendor',
  },
  duo: {
    type: 'vendor',
  },
  'microsoft-defender': {
    type: 'vendor',
  },
  'microsoft-office': {
    type: 'vendor',
    className: 'stroke-gray-50 dark:stroke-gray-950',
  },
  okta: {
    type: 'vendor',
  },
  slack: {
    type: 'vendor',
  },
  fireeyehx: {
    type: 'vendor',
  },
  'palo-alto-networks': {
    type: 'vendor',
  },
  'sentinel-one': {
    type: 'vendor',
  },
  'z-scaler': {
    type: 'vendor',
  },
  more: {
    type: 'simple',
  },
  'caution-green': {
    type: 'composed',
  },
  'caution-yellow': {
    type: 'composed',
  },
  home: {
    type: 'simple',
  },
  cards: {
    type: 'simple',
  },
  list: {
    type: 'simple',
  },
  exit: {
    type: 'simple',
  },
  triage: {
    type: 'simple',
  },
  alert: {
    type: 'simple',
  },
  settings: {
    type: 'simple',
  },
  incidents: {
    type: 'simple',
  },
  'circle-carrot-red': {
    type: 'composed',
  },
  involved: {
    type: 'simple',
  },
  target: {
    type: 'composed',
  },
  play: {
    type: 'simple',
  },
  circle: {
    type: 'simple',
    stroke: true,
  },
  'circle-minus': {
    type: 'simple',
    stroke: true,
  },
  'circle-check': {
    type: 'simple',
  },
  'circle-close': {
    type: 'simple',
  },
  'circle-info': {
    type: 'simple',
  },
  'circle-warning': {
    type: 'simple',
  },
  steps: {
    type: 'simple',
  },
  'carrot-right-grey': {
    type: 'composed',
  },
  'check-grey': {
    type: 'composed',
  },
  containment: {
    type: 'composed',
  },
  remediation: {
    type: 'composed',
  },
  remove: {
    type: 'simple',
  },
  tasks: {
    type: 'simple',
  },
  'check-button-transparent': {
    type: 'simple',
    stroke: true,
  },
  block: {
    type: 'simple',
  },
  organization: {
    type: 'simple',
  },
  share: {
    type: 'simple',
  },
  lock: {
    type: 'simple',
  },
  'lock-solid': {
    type: 'composed',
    stroke: true,
  },
  plus: {
    type: 'simple',
  },
  exclamation: {
    type: 'simple',
  },
  'square-plus': {
    type: 'simple',
    stroke: true,
  },
  'eye-close': {
    type: 'simple',
  },
  'eye-open': {
    type: 'simple',
  },
  edit: {
    type: 'simple',
  },
  flag: {
    type: 'simple',
  },
  'triangle-down': {
    type: 'simple',
  },
  bell: {
    type: 'simple',
  },
  mapping: {
    type: 'simple',
  },
  plug: {
    type: 'simple',
  },
  'false-positive': {
    type: 'composed',
  },
  export: {
    type: 'simple',
  },
  return: {
    type: 'simple',
  },
  calendar: {
    type: 'simple',
  },
  menu: {
    type: 'simple',
  },
  'menu-hamburger': {
    type: 'simple',
  },
  delete: {
    type: 'simple',
  },
  time: {
    type: 'composed',
    stroke: true,
  },
  'circle-check-green': {
    type: 'composed',
    stroke: true,
  },
  switch: {
    type: 'simple',
  },
  'circle-exclamation-red': {
    type: 'composed',
  },
  gear: {
    type: 'simple',
  },
  endpoint: {
    type: 'simple',
  },
  phishing: {
    type: 'simple',
  },
  mitre: {
    type: 'vendor',
  },
  file: {
    type: 'simple',
  },
  process: {
    type: 'simple',
  },
  'bullet-green': {
    type: 'composed',
  },
  shadow: {
    type: 'simple',
  },
  hand: {
    type: 'simple',
  },
  key: {
    type: 'simple',
    stroke: true,
  },
  image: {
    type: 'simple',
  },
  hash: {
    type: 'simple',
  },
  'check-shield': {
    type: 'simple',
  },
  'circle-blocked': {
    type: 'simple',
  },
  paper: {
    type: 'simple',
  },
  globe: {
    type: 'simple',
  },
  ip: {
    type: 'simple',
  },
  trusted: {
    type: 'composed',
    stroke: true,
  },
  'gears-group': {
    type: 'simple',
  },
  upload: {
    type: 'simple',
  },
  paperclip: {
    type: 'simple',
    stroke: true,
  },
  'side-panel': {
    type: 'simple',
  },
  blocked: {
    type: 'composed',
  },
  escalate: {
    type: 'simple',
  },
  'google-workspace': {
    type: 'vendor',
  },
  'user-shared': {
    type: 'simple',
  },
  'error-warning': {
    type: 'simple',
  },
  bec: {
    type: 'simple',
  },
  knowbe4: {
    type: 'vendor',
  },
  mimecast: {
    type: 'vendor',
  },
  'message-ballon': {
    type: 'simple',
  },
  'circle-ignored': {
    type: 'composed',
  },
  review: {
    type: 'simple',
  },
  'suspicious-login': {
    type: 'simple',
  },
  cribl: {
    type: 'vendor',
  },
  tools: {
    type: 'simple',
  },
  'microsoft-teams': {
    type: 'vendor',
  },
  workflows: {
    type: 'simple',
  },
  pulsesecure: {
    type: 'simple',
  },
  'pencil-plus': {
    type: 'simple',
  },
  proofpoint: {
    type: 'simple',
  },
  network: {
    type: 'simple',
  },
  server: {
    type: 'simple',
  },
  'empty-list': {
    type: 'simple',
  },
  'forward-rule': {
    type: 'simple',
  },
  link: {
    type: 'simple',
    stroke: true,
  },
  'alert-empty': {
    type: 'simple',
  },
  'kebab-menu': {
    type: 'simple',
  },
  dashboard: {
    type: 'simple',
  },
  'triage-message': {
    type: 'simple',
  },
  fleet: {
    type: 'vendor',
  },
  'sonic-wall': {
    type: 'vendor',
  },
  cisco: {
    type: 'vendor',
  },
  fortinet: {
    type: 'vendor',
  },
  'download-file': {
    type: 'simple',
  },
  shield: {
    type: 'simple',
  },
  'object-storage': {
    type: 'simple',
  },
  rapid7: {
    type: 'vendor',
  },
  'ms-entra-id': {
    type: 'vendor',
  },
  recipients: {
    type: 'simple',
  },
  artifact: {
    type: 'simple',
  },
  'benign-alert': {
    type: 'simple',
  },
  'malicious-alert': {
    type: 'simple',
  },
  description: {
    type: 'simple',
  },
  'red-flag': {
    type: 'composed',
  },
  notebook: {
    type: 'simple',
  },
  'notebook-empty': {
    type: 'simple',
  },
  question: {
    type: 'simple',
  },
  barracuda: {
    type: 'vendor',
  },
  documentation: {
    type: 'simple',
  },
  atlassian: {
    type: 'vendor',
  },
  netskope: {
    type: 'vendor',
  },
  trendmicro: {
    type: 'vendor',
  },
  'radiant_security': {
    type: 'vendor',
  },
  'audit-logs': {
    type: 'simple',
  },
  app: {
    type: 'simple',
  },
  forcepoint: {
    type: 'vendor',
  },
  logs: {
    type: 'simple',
  },
  avanan: {
    type: 'vendor',
  },
  message: {
    type: 'simple',
  },
  resource: {
    type: 'simple',
  },
  'process-artifact': {
    type: 'simple',
  },
  splunk: {
    type: 'vendor',
  },
  'trusted-cloud': {
    type: 'simple',
  },
  'internal-review': {
    type: 'simple',
  },
  aruba: {
    type: 'vendor',
  },
  manage_engine: {
    type: 'vendor',
  },
  datadog: {
    type: 'vendor',
  },
  dragos: {
    type: 'vendor',
  },
  onepassword: {
    type: 'vendor',
  },
  darktrace: {
    type: 'vendor',
  },
  adaptive_shield: {
    type: 'vendor',
  },
  download: {
    type: 'simple',
  },
  expand: {
    type: 'simple',
  },
  minimize: {
    type: 'simple',
  },
  string: {
    type: 'simple',
  },
  number: {
    type: 'simple',
  },
  'ai-robot': {
    type: 'simple',
  },
  exclude: {
    type: 'simple',
  },
  history: {
    type: 'simple',
  },
  'add-to-search': {
    type: 'simple',
  },
  'open-in-new-tab': {
    type: 'simple',
  },
  log: {
    type: 'simple',
  },
  'alert-brief': {
    type: 'simple',
  },
  checkpoint: {
    type: 'vendor',
  },
  crosshair: {
    type: 'simple',
  },
  chip: {
    type: 'simple',
  },
  elastic: {
    type: 'vendor',
  },
  vectra: {
    type: 'vendor',
  },
  zero_fox: {
    type: 'vendor',
  },
  imperva: {
    type: 'vendor',
  },
  sensor: {
    type: 'simple',
    stroke: true,
  },
  'cloud-resource': {
    type: 'simple',
    stroke: true,
  },
  'file-hash': {
    type: 'simple',
    stroke: true,
  },
  'question-mark': {
    type: 'composed',
  },
  'malicious-indicator': {
    type: 'composed',
  },
  'new-tab': {
    type: 'simple',
  },
  salt: {
    type: 'vendor',
  },
  columns: {
    type: 'simple',
    stroke: true,
  },
  'card-view': {
    type: 'simple',
  },
};

type Props = {
  name: Icons;
  size?: Sizes;
};

const Icon: React.FC<Props & React.HTMLAttributes<SVGElement>> = ({
  name,
  size = 32,
  ...props
}) => {
  if (!name) {
    // eslint-disable-next-line no-console
    console.error('BlastRadius UI: Icon not found.');
    return null;
  }

  const { type, stroke, className: iconClassName } = iconsMapper[name];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [Component, setComponent] = React.useState<any>(null);
  const className = classNames(props.className, 'inline-block', iconClassName, {
    'fill-gray-500': !stroke && type !== 'vendor',
    'fill-black dark:fill-white': !stroke && type === 'vendor',
    'stroke-gray-500': stroke,
  });

  React.useEffect(() => {
    const importIcon = async () => {
      try {
        const isStorybook =
          window?.location?.search?.includes('viewMode=story') ||
          window?.location?.search?.includes('viewMode=docs');

        if (isStorybook) {
          const imported = await import(`./svg/${name}.svg`);
          const IconComponent = imported.default || imported.ReactComponent;
          setComponent(() => IconComponent);
        } else {
          const { ReactComponent } = await import(`./svg/${name}.svg`);
          setComponent(ReactComponent);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to load icon: ${name}`, error);
      }
    };

    if (process.env.ENVIRONMENT !== 'test') {
      importIcon();
    }
  }, [name]);

  function renderComponent() {
    /*
      NOTE: let's keep the default behavior as before of dynamic import
      i.e.: an svg with the name inside for test environment.
    */
    return process.env.ENVIRONMENT === 'test' ? (
      <svg {...props} className={className}>{`${name}.svg`}</svg>
    ) : Component ? (
      <Component {...props} width={size} height={size} className={className} />
    ) : null;
  }

  return <>{renderComponent()}</>;
};

export default Icon;
