import React, { useLayoutEffect } from 'react';
import useShortcut from '../../hooks/use-shortcut';

export type Themes = 'dark' | 'light';
type ThemeModes = 'system' | 'user';

export interface IThemeContext {
  theme: Themes;
  themeMode: ThemeModes;
  setAndSaveTheme: (theme: Themes, themeMode: ThemeModes) => void;
}

const defaultTheme: Themes = 'light';
const defaultThemeMode: ThemeModes = 'system';

/* istanbul ignore next */
const ThemeContext = React.createContext<IThemeContext>({
  theme: defaultTheme,
  themeMode: defaultThemeMode,
  setAndSaveTheme: () => void 0,
});

export const ThemeProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState<Themes>(defaultTheme);
  const [themeMode, setThemeMode] =
    React.useState<ThemeModes>(defaultThemeMode);

  function setThemeAndMode(theme: Themes, themeMode: ThemeModes) {
    setTheme(theme);
    setThemeMode(themeMode);
  }

  useLayoutEffect(() => {
    try {
      localStorage.setItem('theme', theme);
      localStorage.setItem('themeMode', themeMode);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        "Theme preference won't be saved. Personal settings storage is full. This will be fixed in future versions",
      );
      // eslint-disable-next-line no-console
      console.error(error);
    }

    document.body.classList.remove('dark', 'light');
    document.body.classList.add(theme);
  }, [theme, themeMode]);

  React.useEffect(() => {
    let userChosenTheme;
    try {
      userChosenTheme =
        (localStorage.getItem('themeMode') as ThemeModes) === 'user' &&
        (localStorage.getItem('theme') as Themes);
    } catch (e) {
      userChosenTheme = null;
    }

    if (userChosenTheme) {
      setThemeAndMode(userChosenTheme, 'user');
    } else if (window.matchMedia) {
      const isSystemDarkMode = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;

      const systemTheme: Themes = isSystemDarkMode ? 'dark' : 'light';

      setThemeAndMode(systemTheme, 'system');
    } else {
      setThemeAndMode('light', 'system');
    }
  }, []);

  function onThemeShortcut() {
    setThemeAndMode(theme === 'dark' ? 'light' : 'dark', 'user');
  }

  useShortcut([
    { keys: ['l'], addCtrlModifier: true, callback: onThemeShortcut },
  ]);

  return (
    <ThemeContext.Provider
      value={{ theme, themeMode, setAndSaveTheme: setThemeAndMode }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
