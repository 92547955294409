import React from 'react';
import {
  Avatar,
  Badge,
  Bullet,
  Dropdown,
  Icon,
  LogoName,
  Text,
} from '@blastradius/ui';
import useDialog from '@blastradius/ui/hooks/use-dialog';
import Container from '@customer-web-app/domains/shared/components/container';
import Link from 'next/link';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import classNames from 'classnames';
import SessionService from '@customer-web-app/domains/authentication/services/session-service';
import SwitchTenantDialog from '@customer-web-app/domains/tenants/components/switch-tenant-dialog';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import getSettingsMainScreen from '@customer-web-app/domains/settings/services/get-settings-main-screen';
import getIncidentsMainScreen from '@customer-web-app/domains/incidents/services/get-incidents-main-screen';
import useIncidentsCounters from '@customer-web-app/domains/incidents/hooks/use-incidents-counters';
import { useRouter } from 'next/router';
import useShortcut from '@blastradius/ui/hooks/use-shortcut';
import MainSideNavigationIncidents from '@customer-web-app/domains/shared/components/main-side-navigation-incidents';
import { delay } from '@customer-web-app/domains/shared/services/delay';
import MainSideNavigationAlerts from '@customer-web-app/domains/shared/components/main-side-navigation-alerts';
import NewPill from '@customer-web-app/domains/shared/components/new-pill';
import { NewFeaturesContext } from '@customer-web-app/domains/shared/providers/new-features-provider';
import getAlertsMainScreen from '@customer-web-app/domains/alerts/services/get-alerts-main-screen';
import dynamic from 'next/dynamic';
import MainSideNavigatorDropdown from '../main-side-navigator-dropdown';
import getInternalReviewMainScreen from '@customer-web-app/domains/internal-review/services/get-internal-review-main-screen';

const SearchBarDialog = dynamic(
  () => import('@customer-web-app/domains/shared/components/search-bar-dialog'),
);

type NavigationItemProps = {
  icon: React.ComponentProps<typeof Icon>['name'];
  label: React.ReactNode;
  labelClassName?: string;
  badge?: React.ReactNode;
  badgeClassName?: string;
  secondLevelComponent?: React.ReactNode;
  renderSecondLevel?: boolean;
  newPage?: boolean;
  active?: boolean;
  onBadgeClick?: () => void;
};

enum NavigationPaths {
  Alerts = 'alerts',
  Incidents = 'incidents',
  Search = 'search',
  Insights = 'insights',
  Settings = 'settings',
  Workflows = 'workflows',
  Notebooks = 'notebooks',
  Logs = 'logs',
}

const NavigationItem: React.FC<
  NavigationItemProps &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  icon,
  label,
  labelClassName,
  badge,
  badgeClassName,
  href,
  secondLevelComponent,
  renderSecondLevel = true,
  newPage = false,
  active = false,
  onBadgeClick,
  ...props
}) => {
  const { generalEnableSideNavigationSecondLayer } = useFeatureFlags();

  const fadeAnimation = `absolute transition-opacity duration-300 ease-in-out invisible opacity-0
  group-hover:opacity-100 group-hover:visible`;

  const defaultClassName = `relative cursor-pointer hover:bg-gray-800/[.04]
  dark:hover:bg-white/[.06] transition-colors ease-in-out duration-200 group-deep
  justify-between whitespace-nowrap flex outline-none rounded`;

  const contentClassName = `flex w-full px-3 py-2`;

  const className = classNames(
    defaultClassName,
    {
      'grid grid-cols-[1fr_1.85rem]': !!secondLevelComponent,
      'flex w-full': !secondLevelComponent,
    },
    props.className,
  );

  const shouldRenderSecondLayer = React.useMemo(() => {
    return (
      generalEnableSideNavigationSecondLayer &&
      secondLevelComponent &&
      renderSecondLevel
    );
  }, [
    secondLevelComponent,
    renderSecondLevel,
    generalEnableSideNavigationSecondLayer,
  ]);

  function renderContent() {
    return (
      <>
        {newPage && (
          <Bullet color="blue" className="absolute top-[1.063rem] left-1" />
        )}
        <Icon
          name={icon}
          size={24}
          className={classNames(
            `group-deep-hover:fill-black dark:group-deep-hover:fill-white
          transition-colors ease-in-out duration-200 shrink-0`,
            {
              'fill-black dark:fill-white': newPage,
              '!fill-pink-500': active,
            },
          )}
        />
        <Text
          type="body"
          size="small"
          color="text-gray-500"
          className={classNames(
            fadeAnimation,
            `top-[0.813rem] left-11 group-deep-hover:text-black dark:group-deep-hover:text-white`,
            { 'text-black dark:text-white': newPage || active },
            labelClassName,
          )}
        >
          {label}

          {newPage && <NewPill className="-top-0.5 left-[calc(100%+0.5rem)]" />}
        </Text>
      </>
    );
  }

  return (
    <div className={className}>
      {href ? (
        <Link href={href} passHref {...props} className={contentClassName}>
          <>{renderContent()}</>
        </Link>
      ) : (
        <button {...props} className={contentClassName}>
          {renderContent()}
        </button>
      )}

      {shouldRenderSecondLayer && (
        <div className="absolute w-14 h-10 group-deeper top-0 left-auto -right-4">
          <div
            className={classNames(
              fadeAnimation,
              `top-3 left-auto right-6 flex w-6 h-6 items-center justify-center rounded
          group-deep-hover:bg-gray-950/[0.1] dark:group-deep-hover:bg-white/[0.1] -mt-1`,
            )}
          >
            <Icon
              name="carrot-right"
              size={12}
              className={`group-deeper-hover:fill-gray-950 dark:group-deeper-hover:fill-white
          transition-all ease-in-out duration-200`}
            />
            {secondLevelComponent}
          </div>
        </div>
      )}

      {badge && (
        <Badge
          aria-label={`${label} badge button`}
          background="bg-gray-800/[.04] dark:bg-white/[.06]"
          textType="body"
          textSize="x-small"
          color="text-gray-950 dark:text-white"
          className={classNames(
            fadeAnimation,
            'px-2 py-1.5 top-2 right-3 delay-[50ms]',
            badgeClassName,
            {
              'pointer-events-auto': onBadgeClick,
            },
          )}
          onClick={async () => {
            await delay(1);
            onBadgeClick();
          }}
        >
          {badge}
        </Badge>
      )}
    </div>
  );
};

const MainSideNavigation: React.FC<React.HTMLAttributes<HTMLElement>> = (
  props,
) => {
  const router = useRouter();
  const flags = useFeatureFlags();
  const { user, isSuperUser, canAccessMultipleTenants } = useAuthentication();

  const { liveIncidentsCount } = useIncidentsCounters();
  const { newFeatures } = React.useContext(NewFeaturesContext);
  const { openDialog } = useDialog();

  const [disableHover, setDisableHover] = React.useState(false);
  const [renderSecondLevel, setRenderSecondLevel] = React.useState(false);
  const [isNavigatorOpen, setIsNavigatorOpen] = React.useState(false);

  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isNavigatorOpen) {
      setIsUserMenuOpen(false);
    }
  }, [isNavigatorOpen]);

  const [isSearchBarOpen, setIsSearchBarOpen] = React.useState(false);

  const searchBarActivatorId = React.useMemo(
    () => Math.random().toString(36).slice(2),
    [],
  );

  function openSwitchTenantDialog() {
    if (canAccessMultipleTenants) {
      openDialog(SwitchTenantDialog, {
        title: 'Switch Tenant',
        currentTenant: user.tenant,
      });
    }
  }

  useShortcut([
    {
      keys: ['k'],
      addCtrlModifier: true,
      callback: () => setIsSearchBarOpen(true),
    },
    { keys: ['Escape'], callback: () => setIsSearchBarOpen(false) },
    { keys: [';'], addCtrlModifier: true, callback: openSwitchTenantDialog },
  ]);

  const navigationRoutes = {
    incidentsPage: getIncidentsMainScreen({
      hasLiveIncidents: liveIncidentsCount > 0,
      useRedirectRoute: router.pathname === '/incidents',
      enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
      enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
      enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
      enableGenericAlerts:
        flags.alertsEnableTriageRequiredAlertStatus as boolean,
    }),
    alertsPage: getAlertsMainScreen({
      enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
      enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
      enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
      enableCloudAlerts: flags.alertsEnableCloudAlerts as boolean,
      enableGenericAlerts:
        flags.alertsEnableTriageRequiredAlertStatus as boolean,
      enablePriorityFilter: flags.alertsEnablePriorityFilter as boolean,
    }),
    settingsPage: getSettingsMainScreen(flags),
    searchPage: `/search?filter=${encodeURIComponent(
      JSON.stringify({
        globalFilter: { keyword: '' },
      }),
    )}`,
    workflowsPage: '/workflows',
    dashboardPage: `/insights`,
    notebooksPage: '/notebooks/alert',
    logsPage: '/logs',
  };

  const isMacOS = navigator.userAgent.toLowerCase().includes('mac');

  const defaultClassName = `bg-gray-50 dark:bg-gray-950 shadow-2md dark:shadow-sm w-16
  fixed top-0 left-0 h-full border-r border-r-gray-200 dark:border-r-gray-800
  transition-width ease-in-out duration-200 group z-50
  hover:w-[15.25rem]`;

  const className = classNames(
    defaultClassName,
    {
      'pointer-events-none': disableHover,
    },
    props.className,
  );

  // When changing the URL closes the navigator and search bar;
  React.useEffect(() => {
    setIsSearchBarOpen(false);

    setDisableHover(true);

    delay(1000).then(() => {
      setDisableHover(false);
    });
  }, [router.asPath]);

  function isActiveScreen(path: string) {
    const { pathname, query } = router;

    const isCurrentPathname = pathname.includes(path);

    if (path === NavigationPaths.Alerts) {
      return Boolean(query?.alertsPage) || isCurrentPathname;
    }

    if (path === NavigationPaths.Search) {
      return isCurrentPathname && !query?.alertsPage;
    }

    return isCurrentPathname;
  }

  const isDashboardEnabled = Object.values(
    flags?.insightsEnableInsights || {},
  ).some((dashboardSection) => dashboardSection);

  React.useEffect(() => {
    const navigatorUserMenu = document.getElementById('navigator-user-menu');

    navigatorUserMenu.addEventListener('mouseleave', () =>
      setIsUserMenuOpen(false),
    );

    return () => {
      navigatorUserMenu.removeEventListener('mouseleave', () =>
        setIsUserMenuOpen(false),
      );
    };
  }, []);

  return (
    <>
      <aside
        {...props}
        className={className}
        onMouseEnter={() => {
          setRenderSecondLevel(true);
          setIsNavigatorOpen(true);
        }}
        onMouseLeave={async () => {
          setRenderSecondLevel(false);
          setIsNavigatorOpen(false);
        }}
        data-testid="main-side-navigation"
      >
        <Container
          className="h-full flex flex-col"
          width="w-full"
          data-testid="main-side-navigation-container"
        >
          <Link
            href="/"
            className="py-5 pl-5 w-full h-16 bg-pink-500 rounded-br-3xl mb-4 relative"
          >
            <Icon name="radiant_security" size={24} className="!fill-white -mt-1" />
            <LogoName
              className={`w-28 absolute top-4 left-14 duration-150 ease-in-out transition-fade
           invisible opacity-0 group-hover:opacity-100 group-hover:visible -mt-0.5`}
            />
          </Link>
          <section className="h-full flex flex-col justify-between">
            <nav
              className="px-2 flex flex-col justify-between gap-2 h-full"
              data-testid="main-side-navigator-items"
            >
              <div className="flex flex-col gap-2">
                {isDashboardEnabled && (
                  <NavigationItem
                    href={navigationRoutes.dashboardPage}
                    icon="dashboard"
                    label="Insights"
                    newPage={newFeatures['main-navigator-item-dashboard']}
                    active={isActiveScreen(NavigationPaths.Insights)}
                  />
                )}

                <NavigationItem
                  href={navigationRoutes.incidentsPage}
                  icon="incidents"
                  label="Incidents"
                  secondLevelComponent={
                    <MainSideNavigationIncidents disableHover={disableHover} />
                  }
                  renderSecondLevel={renderSecondLevel}
                  newPage={newFeatures['main-navigator-item-incidents']}
                  active={isActiveScreen(NavigationPaths.Incidents)}
                />

                <NavigationItem
                  href={navigationRoutes.alertsPage}
                  icon="alert"
                  label="Alerts"
                  secondLevelComponent={
                    <MainSideNavigationAlerts disableHover={disableHover} />
                  }
                  renderSecondLevel={renderSecondLevel}
                  newPage={newFeatures['main-navigator-item-alerts']}
                  active={isActiveScreen(NavigationPaths.Alerts)}
                />

                {flags.workflowsEnablePage && (
                  <NavigationItem
                    href={navigationRoutes.workflowsPage}
                    icon="workflows"
                    label="Workflows"
                    newPage={newFeatures['main-navigator-item-workflows']}
                    active={isActiveScreen(NavigationPaths.Workflows)}
                  />
                )}

                {flags.notebooksEnablePage && (
                  <NavigationItem
                    href={navigationRoutes.notebooksPage}
                    icon="notebook"
                    label="Notebooks"
                    newPage={newFeatures['main-navigator-item-notebook']}
                    active={isActiveScreen(NavigationPaths.Notebooks)}
                  />
                )}

                {flags.logsEnablePage && (
                  <NavigationItem
                    href={navigationRoutes.logsPage}
                    icon="logs"
                    label="Log Management"
                    newPage={newFeatures['main-navigator-item-logs']}
                    active={isActiveScreen(NavigationPaths.Logs)}
                  />
                )}

                <NavigationItem
                  href={navigationRoutes.searchPage}
                  icon="search"
                  label="Search"
                  badge={isMacOS ? '⌘ K' : 'Ctrl + K'}
                  onBadgeClick={() => setIsSearchBarOpen(true)}
                  id={searchBarActivatorId}
                  newPage={newFeatures['main-navigator-item-search']}
                  active={isActiveScreen(NavigationPaths.Search)}
                />
              </div>

              <div className="pb-2 transition-all ease-in-out duration-150">
                <NavigationItem
                  href={navigationRoutes.settingsPage}
                  icon="gear"
                  label="Settings"
                  newPage={newFeatures['main-navigator-item-settings']}
                  active={isActiveScreen(NavigationPaths.Settings)}
                />
              </div>
            </nav>

            <div>
              {/* Tenant */}
              <div
                role={canAccessMultipleTenants ? 'button' : 'div'}
                className={classNames(
                  `relative py-5 flex px-4 w-full border-t border-t-gray-200 dark:border-t-gray-800 group-deep`,
                  {
                    'hover:bg-gray-800/[.04] dark:hover:bg-white/[.06] cursor-pointer transition-all ease-in-out duration-200':
                      canAccessMultipleTenants,
                  },
                )}
                aria-label={
                  canAccessMultipleTenants ? 'Open dialog to switch tenant' : ''
                }
                onClick={
                  canAccessMultipleTenants ? openSwitchTenantDialog : null
                }
              >
                <Avatar
                  name={user.tenant.name}
                  alt="Tenant`s initial"
                  className="w-8 h-8 shrink-0 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600"
                  textColor="text-gray-500"
                  colorWhenHoverGroup="group-deep-"
                />
                <div
                  className={`absolute top-5 left-[3.75rem] duration-150 ease-in-out transition-all
              opacity-0 invisible group-hover:opacity-100 group-hover:visible flex items-center`}
                >
                  <Text
                    type="label"
                    size="regular"
                    className="mt-2 truncate max-w-[7rem]"
                  >
                    {user.tenant.name}
                  </Text>

                  {canAccessMultipleTenants && (
                    <Badge
                      background="bg-gray-800/[.04] dark:bg-white/[.06]"
                      textType="body"
                      textSize="x-small"
                      color="text-gray-950 dark:text-white"
                      className={classNames(
                        'absolute left-[7.5rem] transition-opacity duration-300 ease-in-out invisible opacity-0 group-hover:opacity-100 group-hover:visible px-2 py-1.5 top-0.5 right-3 delay-[50ms] min-w-[2.5rem] whitespace-nowrap',
                      )}
                    >
                      {isMacOS ? '⌘ ;' : 'Ctrl + ;'}
                    </Badge>
                  )}
                </div>
              </div>

              {/* User */}
              <section className="relative" id="navigator-user-menu">
                <div
                  role="button"
                  aria-label={`${isUserMenuOpen ? 'Close' : 'Open'} user menu`}
                  aria-expanded={isUserMenuOpen}
                  className={classNames(
                    'flex w-full relative py-5 px-4 hover:bg-gray-800/[.04] dark:hover:bg-white/[.06] cursor-pointer transition-all ease-in-out duration-200 group-deep',
                    {
                      'bg-gray-800/[.04] dark:bg-white/[.06]': isUserMenuOpen,
                    },
                  )}
                  onClick={() => setIsUserMenuOpen((prev) => !prev)}
                >
                  <Avatar
                    src={user.profilePictureUrl}
                    name={user.name}
                    alt="Your profile picture"
                    className="!bg-gray-500 w-8 h-8 shrink-0"
                    textColor="text-white dark:text-gray-950"
                    colorWhenHover={!isUserMenuOpen}
                    colorWhenHoverGroup="group-deep-"
                  />
                  <div
                    className={`absolute top-5 left-[3.75rem] duration-150 ease-in-out transition-all
              opacity-0 invisible group-hover:opacity-100 group-hover:visible flex flex-col`}
                  >
                    <Text
                      type="label"
                      size="regular"
                      className="mb-1 truncate max-w-[8.5rem]"
                    >
                      {user.name}
                    </Text>
                    <Text
                      type="body"
                      size="small"
                      color="text-gray-500"
                      className="truncate max-w-[8rem]"
                    >
                      {user.email}
                    </Text>
                    <Icon
                      name="carrot-right"
                      className="absolute left-36 top-1.5"
                      size={16}
                    />
                  </div>
                </div>

                <MainSideNavigatorDropdown
                  visible={isUserMenuOpen}
                  isMainNavigatorOpen={isNavigatorOpen}
                >
                  {isSuperUser && (
                    <Dropdown.Item
                      iconLeft="internal-review"
                      iconSize={18}
                      as="a"
                      href={getInternalReviewMainScreen()}
                    >
                      Internal Review
                    </Dropdown.Item>
                  )}

                  {isSuperUser && (
                    <Dropdown.Item
                      iconLeft="tools"
                      iconSize={18}
                      as="a"
                      href="/admin/organization/radiant-security-users"
                    >
                      Concierge
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    iconLeft="exit"
                    iconSize={18}
                    onClick={async () => {
                      await SessionService.logoutFromClient();
                    }}
                  >
                    Sign out
                  </Dropdown.Item>
                </MainSideNavigatorDropdown>
              </section>
            </div>
          </section>
        </Container>
      </aside>
      <SearchBarDialog
        opened={isSearchBarOpen}
        setOpened={setIsSearchBarOpen}
        searchBarActivatorId={searchBarActivatorId}
      />
    </>
  );
};

export default MainSideNavigation;
