import React, { useState, ChangeEvent, KeyboardEvent, useRef } from 'react';
import { Icon, Tooltip } from '@blastradius/ui';
import classNames from 'classnames';

type Props = {
  placeholder?: string;
  errorMessageTemplate?: string;
  classNameContainer?: string;
  validation?: (value: string) => boolean;
  onChange?: (values: Value[]) => void;
};

type Value = {
  value: string;
  isValid: boolean;
  error?: string;
};

const InputTags = ({
  placeholder,
  validation,
  errorMessageTemplate,
  onChange,
  classNameContainer = '',
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [output, setOutput] = useState<Value[]>([]);
  const inputRef = useRef(null);

  const createErrorMessage = (value: string): string => {
    if (errorMessageTemplate) {
      return errorMessageTemplate.replace('{{value}}', value);
    }
    return 'Invalid value format';
  };

  // Handle input change
  const handleInputChange = ({ target: { value } }: ChangeEvent): void => {
    setInputValue(value);
  };

  // Handle key down events (space or enter to add new value)
  const handleKeyDown = (e: KeyboardEvent) => {
    if ((e.key === ' ' || e.key === 'Enter') && inputValue.trim()) {
      e.preventDefault();

      const newValue = inputValue.trim();

      // Check if value already exists
      if (!output.some(({ value }) => value === newValue)) {
        const isValid = validation ? validation(newValue) : true;
        setOutput([
          ...output,
          {
            value: newValue,
            error: isValid ? undefined : createErrorMessage(newValue),
            isValid,
          },
        ]);
      }
      setInputValue('');
    } else if (
      e.key === 'Backspace' &&
      inputValue === '' &&
      output.length > 0
    ) {
      // Remove the last email when backspace is pressed and input is empty
      const newOutput = [...output];
      newOutput.pop();
      setOutput(newOutput);
    }
  };

  React.useEffect(() => {
    if (onChange) {
      onChange(output);
    }
  }, [output]);

  // Handle removing an value
  const removeValue = (index: number) => {
    const newOutput = [...output];
    newOutput.splice(index, 1);
    setOutput(newOutput);
  };

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="w-full mx-auto">
      <div
        className={classNames(
          `flex flex-wrap h-full gap-2 content-start px-4 py-2 overflow-y-scroll 
        bg-white dark:bg-white/[.06] hover:bg-gray-950/[.04] hover:dark:bg-white/[.12] 
        outline-none  border border-solid border-gray-950/[.25] dark:border-white/[.25] focus:border-black dark:focus:border-white
        text-[.75rem] text-black dark:text-white  placeholder:text-gray-950/[0.4] placeholder:dark:text-white/[.4]
        transition-all ease-in-out duration-200 appearance-none scrollbar-none h-12 rounded cursor-text`,
          classNameContainer,
        )}
        onClick={handleContainerClick}
      >
        {output.map((item, index) => (
          <div
            key={index}
            className={`flex content-start gap-2 px-2 py-1 text-sm rounded ${
              item.isValid
                ? 'text-gray-950 dark:text-white border border-solid  border-gray-950/[.25] dark:border-white/[.25]'
                : 'bg-red-100 text-red-800 border border-solid border-red-300'
            } max-w-full truncate`}
          >
            {!item.isValid && item.error ? (
              <Tooltip
                text={item.error}
                placement="top"
                tooltipTextAlign="left"
                className="flex gap-2"
              >
                <span className="truncate">{item.value}</span>
                <Icon
                  name="close"
                  size={16}
                  role="button"
                  className="!fill-red-800"
                  onClick={() => removeValue(index)}
                  aria-label="Remove value"
                />
              </Tooltip>
            ) : (
              <>
                <span className="truncate">{item.value}</span>
                <Icon
                  name="close"
                  size={16}
                  role="button"
                  onClick={() => removeValue(index)}
                  aria-label="Remove value"
                />
              </>
            )}
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          className="flex-grow min-w-20 outline-none p-1 text-black dark:text-white placeholder:text-gray-950/[0.4] placeholder:dark:text-white/[.4] bg-transparent"
          placeholder={placeholder}
          value={inputValue}
          aria-label="tags-input"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default InputTags;
