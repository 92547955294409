import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TooltipStyles } from '@blastradius/ui';
import { Themes } from '@blastradius/ui/themes';
import useTheme from '@blastradius/ui/hooks/use-theme';
import classNames from 'classnames';

const tooltipThemeMapper: {
  [key in Themes]: React.ComponentProps<typeof ReactTooltip>['variant'];
} = {
  light: 'dark',
  dark: 'light',
};
type Place = 'top' | 'right' | 'bottom' | 'left';

type Props = {
  text: string;
  placement?: Place;
  tooltipTextWidth?: string;
  tooltipTextAlign?: 'left' | 'right' | 'center';
};

const Tooltip = ({
  text = '',
  placement = 'top',
  children,
  tooltipTextWidth,
  tooltipTextAlign,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const defaultClassName = 'hover:z-[100]';
  const className = classNames(defaultClassName, props.className);

  const { theme } = useTheme();
  const tooltipStyle = TooltipStyles(theme);

  const tooltipId = Math.random().toString(36).slice(2);
  return (
    <div
      {...props}
      className={className}
      data-tooltip-id={tooltipId}
      data-tooltip-content={text}
    >
      {children}
      <ReactTooltip
        id={tooltipId}
        place={placement}
        variant={tooltipThemeMapper[theme]}
        style={{
          backgroundColor: tooltipStyle['data-background-color'],
          maxWidth: tooltipTextWidth,
          textAlign: tooltipTextAlign,
          wordBreak: tooltipTextAlign === 'left' ? 'break-word' : 'normal',
        }}
        arrowColor={tooltipStyle['data-background-color']}
      />
    </div>
  );
};

export default Tooltip;
