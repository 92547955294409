import React from 'react';
import Icon from '../icon';
import Text from '../text';
import Dropdown from '../../core-components/dropdown';
import classNames from 'classnames';

type Colors = 'red' | 'green' | 'yellow';

type Props = {
  label: string;
  primaryDropdown: React.ReactNode;
  secondaryDropdown: React.ReactNode;
  color: Colors;
  primaryDropdownOnClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  secondaryDropdownOnClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
};

const buttonDropdownBackgroundMapper = {
  red: 'bg-gradient-to-b from-[#FF1560] to-[#FF5D60] hover:bg-gradient-to-b hover:from-[#FF1560] hover:to-[#FF5D60] hover:brightness-90',
  green:
    'bg-gradient-to-b from-[#00CDAF] to-[#009882] hover:bg-gradient-to-b hover:from-[#00CDAF] hover:to-[#009882] hover:brightness-90',
  yellow:
    'bg-gradient-to-b from-[#FFC28D] to-[#EE7307] hover:bg-gradient-to-b hover:from-[#FFB800] hover:to-[#FF9800] hover:brightness-90',
};

function ButtonDropdown({
  label,
  primaryDropdown,
  secondaryDropdown,
  primaryDropdownOnClick,
  secondaryDropdownOnClick,
  color,
}: Props & React.HTMLAttributes<HTMLButtonElement>) {
  const buttonDropdownBackground = buttonDropdownBackgroundMapper[color];

  return (
    <div className="flex items-center rounded">
      <Dropdown>
        <Dropdown.Activator
          className={classNames(
            buttonDropdownBackground,
            'px-3 py-[0.375rem] border-r border-r-white rounded-l',
          )}
          data-testid="primary-button-dropdown"
          onClick={(e) => {
            primaryDropdownOnClick?.(e);
          }}
        >
          <button>
            <Text type="body" size="small" color="text-white">
              {label}
            </Text>
          </button>
        </Dropdown.Activator>
        {primaryDropdown}
      </Dropdown>

      <Dropdown>
        <Dropdown.Activator
          className={classNames(
            buttonDropdownBackground,
            'px-3 py-[0.375rem] rounded-r',
          )}
          data-testid="secondary-button-dropdown"
          onClick={(e) => {
            secondaryDropdownOnClick?.(e);
          }}
        >
          <button>
            <Icon name="carrot-down" size={16} className="fill-white" />
          </button>
        </Dropdown.Activator>
        {secondaryDropdown}
      </Dropdown>
    </div>
  );
}

export default ButtonDropdown;
