import { SearchFilterParams } from '@customer-web-app/domains/search/services/search-filter-service';
import { AlertStatuses } from '@customer-web-app/domains/alerts/models/alert';

type Params = {
  filters?: SearchFilterParams['alertFilter'];
  enableEndpointAlerts?: boolean;
  enableIdentityAlerts?: boolean;
  enableNetworkAlerts?: boolean;
  enableCloudAlerts?: boolean;
  enableGenericAlerts?: boolean;
  enablePriorityFilter?: boolean;
};

const defaultParamValues: Params = {
  filters: {},
  enableEndpointAlerts: true,
  enableIdentityAlerts: true,
  enableNetworkAlerts: false,
  enableCloudAlerts: false,
  enableGenericAlerts: false,
  enablePriorityFilter: false,
};

export default function getAlertsMainScreen(params?: Params) {
  const { filters } = {
    ...defaultParamValues,
    ...params,
  };

  const alertsFilters = {
    alertStatus: [AlertStatuses.RecommendedMalicious],
    ...filters,
  };

  return `/alerts?filter=${encodeURIComponent(
    JSON.stringify({
      globalFilter: { entities: ['Alert'] },
      alertFilter: {
        ...alertsFilters,
      },
    }),
  )}`;
}
