import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';

type Icons = React.ComponentProps<typeof Icon>['name'];
type IconSizes = React.ComponentProps<typeof Icon>['size'];

type BorderTypes = 'red' | 'green' | 'gray' | 'yellow' | 'light-gray';

const Border: React.FC<{
  border: BorderTypes;
  cardHeight: number;
  hasCollapse: boolean;
  fullHeightBorder?: boolean;
  borderWidth?: string;
}> = ({
  border,
  cardHeight,
  hasCollapse,
  fullHeightBorder,
  borderWidth = '',
}) => {
  /*
    NOTE 1: By default the card renders the border as big, and the default height is 0 until the reference get the height.
    NOTE 2: When card has collapse the values are returned inverted because of the effect and we can't wait the effect finishes
            because this will generates a bad UX. So when "hasCollapse" is true the component renders the border with the opposite
            of true sentence.
  */
  const isBigCard = cardHeight === 0 || cardHeight > 80;
  const isSmallCard = cardHeight >= 1 && cardHeight <= 80;

  return (
    <div
      className={classNames(
        'flex items-center absolute h-full top-0 -left-0.5',
        borderWidth,
        {
          'w-0.5': !borderWidth,
        },
      )}
      data-testid="border"
    >
      <div
        className={classNames(
          'rounded-tl rounded-bl transition-all duration-75',
          borderWidth,
          {
            'bg-gradient-to-b from-red-500 to-pink-500': border === 'red',
            'bg-green-500': border === 'green',
            'bg-gray-500': border === 'gray',
            'bg-yellow-600': border === 'yellow',
            'bg-gray-100 dark:bg-gray-900': border === 'light-gray',
            'h-full': fullHeightBorder,
            'h-[calc(100%-2rem)]':
              !fullHeightBorder && (hasCollapse ? !isBigCard : isBigCard),
            'h-[calc(100%-1rem)]':
              !fullHeightBorder && (hasCollapse ? !isSmallCard : isSmallCard),
            'w-0.5': !borderWidth,
          },
        )}
      />
    </div>
  );
};

const RightIcon: React.FC<{
  icon: Icons;
  iconSize: IconSizes;
  iconClassName?: string;
}> = ({ icon, iconSize, iconClassName }) => (
  <div
    className="absolute right-0 top-0 rounded-tr-x-sm rounded-bl-2xl 
                  flex justify-center items-center w-10 h-10
                bg-gray-50 dark:bg-gray-950"
  >
    <Icon
      name={icon}
      size={iconSize}
      className={classNames('ml-1 -mt-0.5', iconClassName)}
      data-testid="right-icon"
    />
  </div>
);

type Props = {
  border?: BorderTypes;
  icon?: Icons;
  iconSize?: IconSizes;
  background?: string;
  clickable?: boolean;
  className?: string;
  as?: React.ElementType;
  position?: string;
  iconClassName?: string;
  disabled?: boolean;
  target?: string;
  tagsToDisableClick?: string[];
  fullHeightBorder?: boolean;
  borderWidth?: string;
};

const Card = React.forwardRef<
  HTMLElement,
  Props & React.HTMLAttributes<HTMLElement>
>(
  (
    {
      border,
      icon,
      iconSize,
      background = 'bg-white dark:bg-gray-900',
      clickable = false,
      as = 'div',
      position = 'relative',
      disabled,
      iconClassName = '',
      tagsToDisableClick = [],
      fullHeightBorder = false,
      borderWidth,
      children,
      ...props
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref,
  ) => {
    const defaultClassName = 'rounded';
    const clickableClassName = classNames('cursor-pointer', {
      'transition-shadow duration-500 ease-in-out hover:shadow-2xl dark:hover:shadow-md select-none':
        background !== 'transparent',
    });
    const [cardHeight, setCardHeight] = React.useState(0);
    const [hasCollapse, setHasCollapse] = React.useState(false);
    const ref = React.createRef<HTMLElement>();

    React.useEffect(() => {
      if (border && ref.current) {
        const { height } = ref.current.getBoundingClientRect();

        setHasCollapse(
          ref.current.querySelector('[aria-label="Collapse Content"]') !=
            null &&
            ref.current.querySelector('[data-had-interaction="true"]') != null,
        );
        setCardHeight(height + 1);
      }
    }, [ref]);

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
      if (
        !['input', 'button', ...tagsToDisableClick].includes(
          (e.target as HTMLElement).tagName.toLowerCase(),
        ) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !['switch'].includes((e.target as any)?.role)
      ) {
        props?.onClick?.(e);
      }
    }

    const className = classNames(
      props.className,
      background,
      position,
      defaultClassName,
      {
        'cursor-default': !clickable && as === 'a',
        [clickableClassName]: clickable,
        'border border-solid border-gray-200 dark:border-gray-900':
          background === 'transparent',
        'shadow-xl dark:shadow-sm': background !== 'transparent',
        'pointer-events-none': disabled,
      },
    );

    return React.createElement(
      as,
      {
        ...props,
        className,
        onClick: handleClick,
        ref,
      },
      <>
        {border && (
          <Border
            border={border}
            cardHeight={cardHeight}
            hasCollapse={hasCollapse}
            fullHeightBorder={fullHeightBorder}
            borderWidth={borderWidth}
          />
        )}
        {children}
        {icon && (
          <RightIcon
            icon={icon}
            iconSize={iconSize}
            iconClassName={iconClassName}
          />
        )}
      </>,
    );
  },
);

export default Card;
