import React from 'react';
import { Icon, Text, InputCheckbox, Tooltip } from '@blastradius/ui';
import classNames from 'classnames';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import styles from './input-select.module.scss';
import InputText from '../input-text';

type Option = {
  id: string;
  value: string;
  description?: string;
  prefixElement?: React.ReactNode;
  toolTipValue?: string;
  disabled?: boolean;
};

type CustomOption = {
  value: string;
  onCustomOptionClick: () => void;
  prefixElement?: React.ReactNode;
};

type Props<T extends FieldValues> = {
  options: Option[];
  name: string;
  icon?: React.ComponentProps<typeof Icon>['name'];
  multiplePlaceholderCopy?: string;
  multiple?: boolean;
  form: UseFormReturn<T, FieldValues>;
  onSelect?: ({ values, value }: { values: string[]; value: string }) => void;
  transparent?: boolean;
  defaultSelectedOptionID?: Option['id'];
  customOptions?: CustomOption[];
  listClassName?: string;
  optionTextClassName?: string;
  withTextTooltip?: boolean;
  activatorTextType?: React.ComponentProps<typeof Text>['type'];
  activatorTextSize?: React.ComponentProps<typeof Text>['size'];
  withPlainTextActivator?: boolean;
  withSearch?: boolean;
  wrapperClassName?: string;
  titleClassName?: string;
};

type ListItemProps = {
  value?: string;
  description?: string;
  prefixElement?: React.ReactNode;
  toolTipValue?: string;
  optionTextClassName?: string;
  activatorTextType?: React.ComponentProps<typeof Text>['type'];
  activatorTextSize?: React.ComponentProps<typeof Text>['size'];
  withTextTooltip?: boolean;
  disabled?: boolean;
};

const InputSelectListItem: React.FC<
  ListItemProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  value,
  description,
  prefixElement,
  toolTipValue,
  optionTextClassName = '',
  activatorTextType = 'body',
  activatorTextSize = 'regular',
  withTextTooltip,
  disabled = false,
  ...props
}) => {
  const defaultClassName = 'py-1 flex items-center w-full';
  const className = classNames(props.className, defaultClassName, {
    'gap-2': prefixElement,
    'pointer-events-none opacity-50': disabled,
  });

  return (
    <div {...props} className={className}>
      {prefixElement}
      {!withTextTooltip ? (
        <div className="flex flex-col gap-1">
          <Text
            type={activatorTextType}
            size={activatorTextSize}
            className={optionTextClassName}
          >
            {value}
          </Text>
          {description && (
            <Text type="body" size="small" color="text-gray-500">
              {description}
            </Text>
          )}
        </div>
      ) : (
        <Text
          type={activatorTextType}
          size={activatorTextSize}
          className={optionTextClassName}
          render={({ tooltipId, tooltipStyle, ref }) => (
            <div
              className={optionTextClassName}
              data-tooltip-id={tooltipId}
              data-tooltip-content={value}
              ref={ref}
              {...tooltipStyle}
            >
              {value}{' '}
              {description && (
                <Text type="body" size="small" color="text-gray-500">
                  {description}
                </Text>
              )}
            </div>
          )}
          tooltipClassName="w-[15.75rem] whitespace-normal"
        />
      )}
      {toolTipValue && (
        <Tooltip
          text={toolTipValue}
          placement="top"
          className="absolute right-2 top-0"
        >
          <Icon name="circle-info" color="fill-gray-500" size={16} />
        </Tooltip>
      )}
    </div>
  );
};

const InputSelect = <T extends FieldValues>({
  options,
  defaultSelectedOptionID,
  name,
  multiplePlaceholderCopy,
  icon = 'carrot-down',
  multiple = false,
  form,
  transparent = false,
  onSelect,
  placeholder,
  customOptions = [],
  listClassName,
  optionTextClassName = '',
  withTextTooltip = false,
  activatorTextType = 'body',
  activatorTextSize = 'regular',
  withPlainTextActivator = false,
  withSearch = false,
  wrapperClassName = '',
  titleClassName = '',
  ...props
}: Props<T> & Omit<React.AllHTMLAttributes<HTMLSelectElement>, 'form'>) => {
  const [search, setSearch] = React.useState('');
  const [isSelectOpened, setIsSelectOpened] = React.useState(false);
  const [defaultOptions, setDefaultOptions] = React.useState<Option[]>(options);

  const { register, watch, setValue } = form as UseFormReturn<FieldValues>;

  const selectedValues = watch(name);

  React.useEffect(() => {
    setDefaultOptions(options);
  }, [options]);

  React.useEffect(() => {
    if (onSelect && selectedValues?.length) {
      onSelect({ [`value${multiple ? 's' : ''}`]: selectedValues } as {
        values: string[];
        value: string;
      });
    }
  }, [selectedValues]);

  React.useEffect(() => {
    if (defaultSelectedOptionID) {
      setValue(name, defaultSelectedOptionID);
    }
  }, [defaultSelectedOptionID]);

  const selectInputActivatorId = React.useMemo(
    () => Math.random().toString(36).slice(2),
    [],
  );

  function closeInputSelect(e: MouseEvent) {
    const hasSelectInputActivatorId = e
      .composedPath()
      .some(
        (path) =>
          (path as HTMLElement).getAttribute?.('id') === selectInputActivatorId,
      );

    if (!hasSelectInputActivatorId) {
      setSearch('');
      setIsSelectOpened(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', closeInputSelect);

    return () => {
      document.removeEventListener('click', closeInputSelect);
    };
  }, []);

  function findOptionByID(optionID: string) {
    return options.find(({ id }) => id === optionID);
  }

  function getSelectName() {
    if (multiple) {
      if (selectedValues?.length === options?.length) {
        return 'All';
      }

      if (selectedValues?.length > 1) {
        return (
          <div className="flex items-center justify-center gap-2">
            <Icon
              name="remove"
              size={16}
              role="button"
              aria-label="Clear selected items"
              onClick={() => {
                setValue(name, []);
                form.trigger();
              }}
            />
            {`${selectedValues.length} ${multiplePlaceholderCopy}`}
          </div>
        );
      }

      if (selectedValues?.length === 1) {
        return (
          <InputSelectListItem
            value={findOptionByID(selectedValues[0])?.value}
            prefixElement={findOptionByID(selectedValues[0])?.prefixElement}
            className="gap-4 pointer-events-none"
            optionTextClassName={optionTextClassName}
            withTextTooltip={withTextTooltip}
            activatorTextSize={activatorTextSize}
            activatorTextType={activatorTextType}
          />
        );
      }
    }

    if (!multiple) {
      if (selectedValues?.length > 0) {
        return (
          <InputSelectListItem
            value={findOptionByID(selectedValues)?.value}
            prefixElement={findOptionByID(selectedValues)?.prefixElement}
            className="gap-4 pointer-events-none"
            optionTextClassName={optionTextClassName}
            withTextTooltip={withTextTooltip}
            activatorTextSize={activatorTextSize}
            activatorTextType={activatorTextType}
          />
        );
      }
    }

    if (placeholder) {
      return placeholder;
    }

    return multiple ? 'Select one or more values' : 'Select a value';
  }

  const selectDropdownClassName = `flex items-center justify-between p-4 min-w-[13.25rem] w-full h-12
    border border-transparent hover:bg-gray-950/[.04] dark:hover:bg-white/[.12] rounded cursor-pointer
    transition-all duration-200 ease-in-out group`;
  const selectClassName = classNames(selectDropdownClassName, props.className, {
    'hover:border-gray-950/[.25] dark:hover:border-white/[.25]': transparent,
    'border !border-gray-950/[.25] dark:!border-white/[.25] bg-white dark:bg-white/[.06]':
      !transparent,
    'border border-gray-950/[.25] dark:border-white/[.25] bg-gray-950/[.04] dark:bg-white/[.12]':
      isSelectOpened,
  });

  const plainTextDefaultClassName = 'flex items-center justify-center';
  const plainTextClassName = classNames(
    plainTextDefaultClassName,
    props.className,
  );

  const defaultListClassName = classNames(
    `absolute flex flex-col items-center bg-white dark:bg-gray-900 shadow-sm
  justify-center min-w-[13.25rem] w-full rounded overflow-hidden`,
    {
      'top-14': !withPlainTextActivator,
      'top-12': withPlainTextActivator,
    },
  );

  React.useEffect(() => {
    if (withSearch) {
      setDefaultOptions(options.filter(({ value }) => value.includes(search)));
    }
  }, [search]);

  return (
    <div
      className={classNames('relative', wrapperClassName)}
      id={selectInputActivatorId}
    >
      <div
        role="listbox"
        className={
          !withPlainTextActivator ? selectClassName : plainTextClassName
        }
        onClick={() => setIsSelectOpened((prev) => !prev)}
      >
        <Text
          type={activatorTextType}
          size={activatorTextSize}
          className={classNames(titleClassName, {
            'font-bold border bg-gray-100 dark:bg-white/[.12] border-gray-500 p-1 rounded cursor-pointer word-break':
              withPlainTextActivator,
          })}
        >
          {getSelectName()}
        </Text>
        {icon && !withPlainTextActivator && (
          <Icon
            size={12}
            name={icon}
            className={classNames(
              'group-hover:flex fill-gray-500 transition-all duration-200 ease-in-out',
              {
                hidden: transparent && !isSelectOpened,
              },
            )}
          />
        )}
      </div>
      {isSelectOpened && (
        <section className={classNames(defaultListClassName, listClassName)}>
          {withSearch && (
            <div className="pt-4 flex flex-col items-center">
              <InputText
                className="w-[calc(100%-0.5rem)]"
                iconLeft="search"
                type="text"
                value={search}
                handleClearValue={() => {
                  setSearch('');
                }}
                placeholder={placeholder}
                onChange={(e: { target: { value: string } }) =>
                  setSearch(e.target.value)
                }
              />

              <hr className="w-[calc(100%+2.5rem)] border-gray-100 dark:border-gray-800 h-px -ml-2 mt-4" />
            </div>
          )}
          <div className="w-full max-h-[27rem] overflow-y-auto">
            <ul
              className="flex flex-col items-center justify-center w-full gap-4 px-[1.125rem] py-4"
              data-testid="input-select-dropdown"
            >
              {defaultOptions?.length > 0 &&
                defaultOptions.map(
                  (
                    {
                      value,
                      description,
                      prefixElement,
                      id,
                      toolTipValue,
                      disabled = false,
                    },
                    index,
                  ) => (
                    <li key={`${id}_${index}`} className="w-full">
                      {multiple ? (
                        <InputCheckbox
                          label={
                            <InputSelectListItem
                              value={value}
                              description={description}
                              prefixElement={prefixElement}
                              className="gap-2"
                              optionTextClassName={optionTextClassName}
                              withTextTooltip={withTextTooltip}
                              disabled={disabled}
                            />
                          }
                          value={id}
                          {...register(name)}
                        />
                      ) : (
                        <InputSelectListItem
                          value={value}
                          description={description}
                          prefixElement={prefixElement}
                          className={classNames(
                            'justify-start w-full cursor-pointer',
                            [styles.inputSelectItemHover],
                          )}
                          onClick={() => {
                            setValue(name, id);
                            form.trigger();
                            setIsSelectOpened(false);
                          }}
                          toolTipValue={toolTipValue}
                          optionTextClassName={optionTextClassName}
                          withTextTooltip={withTextTooltip}
                          disabled={disabled}
                        />
                      )}
                    </li>
                  ),
                )}

              {!defaultOptions?.length && (
                <section className="flex flex-col items-center justify-center gap-6 p-6 pt-12 pb-12">
                  <Icon name="search" size={56} />
                  <Text type="heading" size="x-small" className="text-center">
                    Your search returned no results
                  </Text>
                </section>
              )}
              {customOptions.length > 0 && (
                <>
                  <div className="w-[calc(100%+2.25rem)] h-px bg-gray-100 dark:bg-gray-800" />

                  {customOptions.map(
                    ({ value, prefixElement, onCustomOptionClick }) => (
                      <li key={value} className="w-full">
                        <InputSelectListItem
                          value={value}
                          prefixElement={prefixElement}
                          className={classNames(
                            'justify-start w-full cursor-pointer',
                            [styles.inputSelectItemHover],
                          )}
                          optionTextClassName={optionTextClassName}
                          onClick={() => {
                            setValue(name, value);
                            onCustomOptionClick();
                            setIsSelectOpened(false);
                          }}
                          withTextTooltip={withTextTooltip}
                        />
                      </li>
                    ),
                  )}
                </>
              )}
            </ul>
          </div>
        </section>
      )}
    </div>
  );
};

export default InputSelect;
