import React from 'react';
import { FieldValues, RefCallBack, UseFormReturn } from 'react-hook-form';
import { ReactDatePickerProps } from 'react-datepicker';
import {
  subDays,
  subYears,
  format,
  startOfDay,
  subMinutes,
  subHours,
  isSameMinute,
  endOfDay,
} from 'date-fns';
import classNames from 'classnames';
import {
  DatePicker,
  InputCheckbox,
  Text,
  InputText,
  ButtonTransparent,
  InputTextProps,
  InputTimeWithSeconds,
} from '@blastradius/ui';

type TimeFrameOptions =
  | 'Last 15 minutes'
  | 'Last 30 minutes'
  | 'Last 4 hours'
  | 'Today'
  | 'Last 7 Days'
  | 'Last 30 Days'
  | 'Last 90 Days'
  | 'Last Year';

const timeFrameMapper: Record<TimeFrameOptions, (currentDate: Date) => Date> = {
  'Last 15 minutes': (currentDate: Date) => subMinutes(currentDate, 15),
  'Last 30 minutes': (currentDate: Date) => subMinutes(currentDate, 30),
  'Last 4 hours': (currentDate: Date) => subHours(currentDate, 4),
  Today: (currentDate: Date) => startOfDay(currentDate),
  'Last 7 Days': (currentDate: Date) => subDays(currentDate, 7),
  'Last 30 Days': (currentDate: Date) => subDays(currentDate, 30),
  'Last 90 Days': (currentDate: Date) => subDays(currentDate, 90),
  'Last Year': (currentDate: Date) => subYears(currentDate, 1),
};

enum PeriodOptions {
  AM,
  PM,
}

const getUserUTC = () => {
  const utc = new Date().getTimezoneOffset() / 60;
  return utc > 0 ? `UTC -${utc}` : `UTC +${utc}`;
};

function combineDateAndTime(
  date: Date,
  time: { hour: string; period: PeriodOptions },
) {
  const [hours, minutes, seconds] = time.hour.split(':');
  const finalDate = new Date(date);

  let adjustedHours = +hours;
  if (time.period === PeriodOptions.PM && hours !== '12') {
    adjustedHours = +hours + 12;
  } else if (time.period === PeriodOptions.AM && hours === '12') {
    adjustedHours = 0;
  }

  finalDate.setHours(adjustedHours, +minutes || 0, +seconds || 0);
  return finalDate;
}

function isValidTimeInput(time: string): boolean {
  // Matches 12-hour format: 1-12:00-59:00-59
  const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9]$/;
  return timeRegex.test(time);
}

function getTimeFrameRange(dateRange: Date[], now: Date) {
  if (dateRange?.some((date) => !date)) {
    return null;
  }

  const [startDate, endDate] = dateRange;

  for (const frame of Object.keys(timeFrameMapper) as TimeFrameOptions[]) {
    const frameStartDate = timeFrameMapper[frame](now);

    if (isSameMinute(frameStartDate, startDate) && isSameMinute(now, endDate)) {
      return frame;
    }
  }

  return null;
}

function formatDateToAMPM(value: string): string {
  if (!value) return '';

  // Split start and end datetimes by " - "
  const [start, end] = value.split(' - ');

  const formatSingleDate = (datetime: string): string => {
    const [datePart, timePart] = datetime.split(' ');

    if (!timePart) return datePart; // Handle case where time is missing

    try {
      const dateObj = new Date(`${datePart} ${timePart}`);

      if (isNaN(dateObj.getTime())) return datetime; // Return original if parsing fails

      return `${datePart} ${dateObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      })}`;
    } catch {
      return datetime; // Return original value if an error occurs
    }
  };

  const formattedStart = formatSingleDate(start.trim());
  const formattedEnd = end ? formatSingleDate(end.trim()) : '';

  return formattedEnd
    ? `${formattedStart} - ${formattedEnd}`
    : `${formattedStart} -`;
}

type Props = {
  startDateName: string;
  endDateName: string;
  initialStartDate?: Date | string;
  initialEndDate?: Date | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, FieldValues>;
  initialTimeFrame?: TimeFrameOptions;
  inputClassName?: string;
  withError?: boolean;
  leftOffset?: string;
  inputTextClassName?: string;
};

type DatePickerHoursMinutesProps = Partial<ReactDatePickerProps> & Props;

const DatePickerHoursMinutesInput = React.forwardRef<
  RefCallBack,
  InputTextProps & {
    setIsOpen: (state: boolean) => void;
    dateRange: Date[];
    isOpen: boolean;
    inputClassName?: string;
    inputTextClassName?: string;
    initialTimeFrame?: TimeFrameOptions;
    now: Date;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      value,
      onClick,
      setIsOpen,
      dateRange,
      isOpen,
      inputClassName,
      inputTextClassName,
      initialTimeFrame,
      now,
      ...props
    },
    ref,
  ) => {
    let formattedInitialTimeFrame = null;
    if (initialTimeFrame !== 'Last 30 minutes') {
      formattedInitialTimeFrame = initialTimeFrame
        ? `${format(new Date((initialTimeFrame as any)?.from), 'MM/dd/yyyy HH:mm:ss')} - ${format(new Date((initialTimeFrame as any)?.to), 'MM/dd/yyyy HH:mm:ss')}`
        : null;
    }
    const valueToDisplay = (value: string) => {
      return (
        getTimeFrameRange(dateRange, now) ??
        formatDateToAMPM(value) ??
        formatDateToAMPM(formattedInitialTimeFrame as string)
      );
    };
    return (
      <InputText
        {...props}
        value={valueToDisplay(value as string)}
        iconRight="carrot-down"
        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          setIsOpen(true);
          onClick?.(e);
        }}
        iconClassName={classNames({
          'rotate-180': isOpen,
        })}
        ref={ref}
        className={inputClassName}
        inputClassName={inputTextClassName}
      />
    );
  },
);

DatePickerHoursMinutesInput.displayName = 'DatePickerHoursMinutesInput';

interface PeriodToggleProps {
  period: PeriodOptions;
  selectedPeriod: PeriodOptions;
  setSelectedPeriod: (period: PeriodOptions) => void;
  label: string;
  roundedSide?: 'left' | 'right';
}

const PeriodToggle: React.FC<PeriodToggleProps> = ({
  period,
  selectedPeriod,
  setSelectedPeriod,
  label,
  roundedSide,
}) => {
  const isActive = period === selectedPeriod;
  const roundedClass = roundedSide === 'left' ? 'rounded-l' : 'rounded-r';

  return (
    <div
      className={classNames(
        'w-10 h-8 flex items-center justify-center cursor-pointer border-[1px] border-black',
        roundedClass,
        isActive ? 'bg-black text-white' : 'bg-white text-black',
      )}
      onClick={() => setSelectedPeriod(period)}
    >
      <Text type="body" size="small" color="inherit">
        {label}
      </Text>
    </div>
  );
};

const DatePickerHoursMinutes = ({
  startDateName,
  endDateName,
  form,
  initialTimeFrame,
  leftOffset = '-64px',
  inputClassName,
  inputTextClassName,
  withError,
  ...props
}: DatePickerHoursMinutesProps) => {
  const { setValue } = form;
  const [now, setNow] = React.useState<Date>(new Date());

  const [timeFrame, setTimeFrame] = React.useState<{
    timeFrame: TimeFrameOptions | undefined;
    calendarDateRange: Date[] | null;
  }>({
    timeFrame: undefined,
    calendarDateRange: null,
  });
  const [dateRange, setDateRange] = React.useState<Date[]>([]);
  const [startDate, endDate] = dateRange;
  const [startHour, setStartHour] = React.useState({
    hour: '00:00:00',
    period: PeriodOptions.AM,
  });
  const [endHour, setEndHour] = React.useState({
    hour: '00:00:00',
    period: PeriodOptions.AM,
  });
  const [focusedInputId, setFocusedInputId] = React.useState<string>('');

  const [isAllDay, setIsAllDay] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  function handleClickStartInput() {
    setIsOpen(true);
  }

  const timeFrameOptions: TimeFrameOptions[] = [
    'Last 15 minutes',
    'Last 30 minutes',
    'Last 4 hours',
    'Today',
    'Last 7 Days',
    'Last 30 Days',
    'Last 90 Days',
    'Last Year',
  ];

  function closeDatePicker(e: MouseEvent) {
    const hasDatePickerInComposition = e
      .composedPath()
      .some((path) =>
        (path as HTMLElement)
          .getAttribute?.('class')
          ?.startsWith('react-datepicker'),
      );

    if (!hasDatePickerInComposition) {
      setIsOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', closeDatePicker);

    return () => {
      document.removeEventListener('click', closeDatePicker);
    };
  }, []);

  // set query time range when the dateRange is changed in the date picker
  React.useEffect(() => {
    const isRelativeTimeFrame = timeFrameOptions.includes(
      timeFrame.timeFrame as TimeFrameOptions,
    );

    if (!isRelativeTimeFrame && !dateRange?.every((date) => date)) {
      return;
    }

    let startDate: Date;
    let endDate: Date;

    if (timeFrame.calendarDateRange) {
      if (isAllDay) {
        startDate = startOfDay(dateRange[0]);
        endDate = endOfDay(dateRange[1]);
      } else {
        startDate = combineDateAndTime(dateRange[0], startHour);
        endDate = combineDateAndTime(dateRange[1], endHour);
      }
    } else if (
      timeFrameOptions.includes(timeFrame.timeFrame as TimeFrameOptions)
    ) {
      startDate = timeFrameMapper[timeFrame.timeFrame as TimeFrameOptions](now);
      endDate = now;
    } else {
      return;
    }

    // Update state and form values with the calculated dates
    setDateRange([startDate, endDate]);
    setValue(startDateName, format(startDate, 'yyyy-MM-dd HH:mm:ss'));
    setValue(endDateName, format(endDate, 'yyyy-MM-dd HH:mm:ss'));
  }, [startHour, endHour, isAllDay, timeFrame, now]);

  // set query time range when the initialTimeFrame is loaded
  React.useEffect(() => {
    if (timeFrameOptions.includes(initialTimeFrame as TimeFrameOptions)) {
      const newDateRange = [
        timeFrameMapper[initialTimeFrame as TimeFrameOptions](new Date()),
        new Date(),
      ];

      setValue(
        startDateName,
        format(startOfDay(newDateRange[0]), 'yyyy-MM-dd HH:mm:ss'),
      );
      setValue(
        endDateName,
        format(endOfDay(newDateRange[1]), 'yyyy-MM-dd HH:mm:ss'),
      );
      setDateRange(newDateRange);
    } else {
      const startDate = form.getValues(startDateName);
      const endDate = form.getValues(endDateName);
      setDateRange([startDate, endDate]);
    }
  }, []);

  React.useEffect(() => {
    const isRelativeTimeFrame = timeFrameOptions.includes(
      timeFrame.timeFrame as TimeFrameOptions,
    );

    if (!isRelativeTimeFrame) {
      return;
    }

    setNow(new Date());
    const interval = setInterval(() => {
      setNow(new Date());
    }, 60000); // 60 seconds

    return () => clearInterval(interval);
  }, [timeFrame.timeFrame]);

  return (
    <DatePicker
      {...props}
      selectsRange
      open={isOpen}
      dateFormat={'MM/dd/yyyy HH:mm:ss'}
      startDate={startDate}
      endDate={endDate}
      onChange={(dates) => {
        setDateRange(dates as unknown as Date[]);
        setTimeFrame({
          timeFrame: undefined,
          calendarDateRange: dates as unknown as Date[],
        });
      }}
      customInput={
        <DatePickerHoursMinutesInput
          initialTimeFrame={initialTimeFrame}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={handleClickStartInput}
          dateRange={dateRange}
          inputClassName={inputClassName}
          inputTextClassName={inputTextClassName}
          now={now}
        />
      }
      shouldCloseOnSelect
      maxDate={new Date()}
      openToDate={endDate || new Date()}
      monthsShown={2}
      className="absolute"
      calendarContainer={({ children }) => {
        return (
          <>
            {/* this div is below the calendar to apply shadow since the calendar is a composition of components */}
            <div
              style={{
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.12)',
              }}
              className="z-0 absolute border-red-400 left-[-490px] w-[666px] h-[456px]"
            />
            <div
              className="react-datepicker__calendar-container"
              style={{
                position: 'absolute',
                top: '100%',
                zIndex: 10,
                left: leftOffset,
                // overrides the children natural shadow
                boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.0)',
              }}
            >
              {children as React.ReactNode}
            </div>
          </>
        );
      }}
    >
      <div className="flex flex-col gap-2 !h-[28rem] w-[7.5rem] bg-white dark:bg-gray-900 -left-[1.25rem] -ml-[0.5rem] rounded-l pl-2">
        {timeFrameOptions.map((timeFrameOption) => (
          <ButtonTransparent
            key={`${timeFrameOption}-date-picker`}
            className={classNames('pl-2 h-10', {
              'bg-gray-800/[.06] dark:bg-white/[.06]':
                getTimeFrameRange(dateRange, now) === timeFrameOption,
            })}
            onClick={() => {
              setTimeFrame({
                timeFrame: timeFrameOption,
                calendarDateRange: null,
              });
              setIsOpen(false);
            }}
          >
            {timeFrameOption}
          </ButtonTransparent>
        ))}
      </div>
      <div className="bg-white dark:bg-gray-900 ml-[1px] mt-10 w-[553px] absolute left-28 rounded-sm -bottom-[8rem]">
        <div className="grid grid-cols-1 pl-2">
          <div className="space-y-4 border-t border-t-gray-200 dark:border-t-gray-800 border-l border-l-gray-200 dark:border-l-gray-800">
            <div className="flex items-center justify-between w-full h-18 py-4 px-6 border-b border-b-gray-200 dark:border-b-gray-700">
              <InputCheckbox
                label="All day"
                withHover={false}
                onChange={() => {
                  setIsAllDay(!isAllDay);
                  setStartHour({
                    hour: '00:00:00',
                    period: PeriodOptions.AM,
                  });
                  setEndHour({
                    hour: '11:59:59',
                    period: PeriodOptions.PM,
                  });
                }}
                checked={isAllDay}
                role="tree"
                id="all-day"
              />
              <Text
                as="label"
                type="body"
                size="small"
                htmlFor="all-day"
                data-testid="input-checkbox-label"
                color="text-gray-950 dark:text-white"
                className="w-16"
              >
                {getUserUTC()}
              </Text>
            </div>

            <div
              className={classNames(
                'w-full px-6 pb-4 flex items-center justify-between',
                {
                  'disabled opacity-50': isAllDay,
                },
              )}
            >
              <div className="flex items-center justify-between">
                <div>
                  <Text
                    type="body"
                    as="label"
                    className="mb-1 mr-6"
                    size="small"
                    color="text-gray-500"
                  >
                    From
                  </Text>
                </div>
                <div className="flex items-center space-x-2">
                  <InputTimeWithSeconds
                    inputClassName="w-[5.5rem] h-8 !pl-2"
                    withButtons={false}
                    initTime={startHour.hour}
                    id="start-hour"
                    focusedInputId={focusedInputId}
                    onFocus={() => setFocusedInputId('start-hour')}
                    onBlur={() => setFocusedInputId('')}
                    onTimeChange={(hour) => {
                      if (hour !== startHour.hour && isValidTimeInput(hour)) {
                        setStartHour((prev) => ({
                          ...prev,
                          hour,
                        }));
                      }
                    }}
                  />
                  <div className="flex items-center">
                    <PeriodToggle
                      period={PeriodOptions.AM}
                      selectedPeriod={startHour.period}
                      setSelectedPeriod={(period) =>
                        setStartHour({ ...startHour, period })
                      }
                      label="AM"
                      roundedSide="left"
                    />
                    <PeriodToggle
                      period={PeriodOptions.PM}
                      selectedPeriod={startHour.period}
                      setSelectedPeriod={(period) =>
                        setStartHour({ ...startHour, period })
                      }
                      label="PM"
                      roundedSide="right"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <Text
                    type="body"
                    as="label"
                    className="mb-1 mr-6"
                    size="small"
                    color="text-gray-500"
                  >
                    To
                  </Text>
                </div>
                <div className="flex items-center space-x-2">
                  <InputTimeWithSeconds
                    inputClassName="w-[5.5rem] h-8 !pl-2"
                    withButtons={false}
                    initTime={endHour.hour}
                    id="end-hour"
                    focusedInputId={focusedInputId}
                    onFocus={() => setFocusedInputId('end-hour')}
                    onBlur={() => setFocusedInputId('')}
                    onTimeChange={(hour) => {
                      if (hour !== endHour.hour && isValidTimeInput(hour)) {
                        setEndHour((prev) => ({
                          ...prev,
                          hour,
                        }));
                      }
                    }}
                  />

                  <div className="flex items-center">
                    <PeriodToggle
                      period={PeriodOptions.AM}
                      selectedPeriod={endHour.period}
                      setSelectedPeriod={(period) =>
                        setEndHour({ ...endHour, period })
                      }
                      label="AM"
                      roundedSide="left"
                    />
                    <PeriodToggle
                      period={PeriodOptions.PM}
                      selectedPeriod={endHour.period}
                      setSelectedPeriod={(period) =>
                        setEndHour({ ...endHour, period })
                      }
                      label="PM"
                      roundedSide="right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatePicker>
  );
};

export default DatePickerHoursMinutes;
